import { component } from "picoapp";
import { notifySlackQuiz } from "@/js/lib/notifySlack";

export default component((node) => {
  const errorDetails = node.querySelector("#error-details");
  if (location.origin.includes("dutch.com")) {
    if (errorDetails) {
      const errorData = JSON.parse(errorDetails.innerText);
      const customerEmail = errorData.customer_email
        ? errorData.customer_email
        : "Anonymous (non logged-in user)";
      const customerProvince = errorData.customer_province
        ? errorData.customer_province
        : "n/a";
      const customerData = errorData.customer_data
        ? errorData.customer_data
        : null;
      const errorType = "404 - Logged-in user";
      const errorMessage = `URL: ${location.href}\n Referrer: ${document.referrer}\n UserAgent: ${navigator.userAgent}\n Customer Email: ${customerEmail}\nCustomer Province: ${customerProvince}\n Customer Data: ${JSON.stringify(customerData)}`;
      notifySlackQuiz(errorType, errorMessage);
    } else {
      const errorType = "404 - Anonymous user";
      const errorMessage = `URL: ${location.href}\n Referrer: ${document.referrer}\n UserAgent: ${navigator.userAgent}`;
      notifySlackQuiz(errorType, errorMessage);
    }
  }
});
