import { RechargeWorker } from "@/workers";
import Bugsnag from "@bugsnag/js";
import type { AlpineComponent } from "alpinejs";

type Props = {
  productHandle: string;
  applyDiscount: boolean;
};

type State = {
  loading: boolean;
  error: boolean;
  upgradeMembership: () => Promise<void>;
};

export function Membership({
  productHandle,
  applyDiscount = false,
}: Props): AlpineComponent<State> {
  const { subscription } = useSubscription();
  const { segmentTrack } = useAnalytics();

  const subscriptionId = computed(() => subscription.value?.subscription_id);
  const rechargeCustomerId = computed(
    () => subscription.value?.recharge_customer_id
  );

  async function getShopifyProduct(handle: string) {
    try {
      const { data } = await axios.get(
        `${window.location.origin}/products/${handle}.json`
      );

      return data?.product;
    } catch (error: any) {
      Bugsnag.notify(error);
    }
  }

  async function upgradeSubscription(variantId: string) {
    return await RechargeWorker.subscription.upgrade.$post({
      json: {
        variant_id: variantId,
        subscription_id: subscriptionId.value,
        rc_customer_id: rechargeCustomerId.value,
        apply_discount: applyDiscount,
      },
    });
  }

  function segmentAnalytics() {
    Promise.all([
      segmentTrack("actionMembershipUpgraded"),
      segmentTrack("Membership Updated", {
        plan_movement: "upgrade",
        former_plan_name: "Unlimited Vet Advice",
        new_plan_name: "Unlimited Vet Advice - Annual Membership",
        former_plan_price: "$35",
        new_plan_price: "$132",
        origin_source: "Upgrade to annual",
      }),
    ]);
  }

  function redirectToAccount() {
    window.location.assign(
      "/account#/?annual_upgraded=true&discount_amount=35"
    );
  }

  return {
    loading: false,
    error: false,
    async upgradeMembership() {
      this.loading = true;
      try {
        const product = await getShopifyProduct(String(productHandle));
        const result = await upgradeSubscription(product.variants[0].id);

        if (result.ok) {
          segmentAnalytics();
          redirectToAccount();
        }
      } catch (error: any) {
        error.value = true;
        Bugsnag.notify(error);
      } finally {
        this.loading = false;
      }
    },
  };
}
