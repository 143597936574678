import { component } from "picoapp";
import Glide from "@glidejs/glide";

export default component((node) => {
  new Glide(node, {
    type: "slider",
    perView: 1,
    gap: 10,
    peek: {
      before: 0,
      after: 60,
    },
    bound: true,
    dragThreshold: 60,
    focusAt: "center",
  }).mount();
});
