// @ts-nocheck
import type { AlpineComponent } from "alpinejs";
import Bugsnag from "@bugsnag/js";

export function CollectionSorting(): AlpineComponent {
  const params = useUrlSearchParams("history");

  return {
    text: "Sort by",
    loading: false,
    targetSection: null,
    targetSectionId: null,
    sortingData: [],

    init() {
      this.targetSection = document.querySelector("#product-grid");
      this.targetSectionId =
        document.querySelector("#product-grid")?.dataset?.sectionId;

      this.$watch("loading", (value) =>
        this.targetSection.classList.toggle("animate-pulse", value)
      );
    },

    async sortProducts(e) {
      if (!this.targetSectionId || !this.targetSectionId) return;

      const sortValue = e.target.value;
      const url = this.createFetchUrl(sortValue);
      this.setSortParam(sortValue);

      if (this.isTemplateCached(url)) {
        this.targetSection.innerHTML = this.getTemplateFromCache(url);
      } else {
        const html = await this.fetchTemplate(url);
        this.sortingData.push({ url, html });
        this.targetSection.innerHTML = html;
      }
    },

    async fetchTemplate(url) {
      if (!url) return null;
      try {
        this.loading = true;
        const response = await fetch(url);
        const data = await response.text();
        return data;
      } catch (error) {
        Bugsnag.notify(error);
        return null;
      } finally {
        this.loading = false;
      }
    },

    createFetchUrl(sortParam) {
      return `${window.location.pathname}?section_id=${this.targetSectionId}&sort_by=${sortParam}`;
    },

    getTemplateFromCache(url) {
      return this.sortingData.find((item) => item.url === url).html;
    },

    isTemplateCached(url) {
      return this.sortingData.some((item) => item.url === url);
    },

    setSortParam(param) {
      params.sort_by = param;
    },
  };
}
