import accordion from "@/js/components/accordion";
import cfForm from "@/js/components/cfForm";
import comparison from "@/js/components/comparison";
import reportError from "@/js/components/reportError";
import reviews from "@/js/components/reviews";
import scrollIntoView from "@/js/components/scrollIntoView";
import specialists from "@/js/components/specialists";
import tableOfContents from "@/js/components/table-of-contents";
import { picoapp } from "picoapp";

const components = {
  specialists,
  reviews,
  cfForm,
  scrollIntoView,
  reportError,
  accordion,
  comparison,
  tableOfContents,
};

export default picoapp(components);
