/* eslint-disable no-undef */
/* eslint-disable no-console */

import { component } from "picoapp";
import { notifySlackQuiz } from "@/js/lib/notifySlack";
import { handleRangeSlider } from "@/js/lib/cfFormUtils.js";
import { resetCustomerData } from "@/js/lib/customerData.js";
import { trackCompletedStep } from "@/js/lib/cfFormTracking.js";

const isDiagnosis = window.location.pathname.includes("-diagnosis");
const isMedicalHistory = window.location.pathname.includes("-medical-history");
const isAssessment = isDiagnosis || isMedicalHistory;

function handleProgressBar() {
  const quizNav = document.querySelector("[data-quiz-progress-bar]");
  const stepCounter = document.querySelector(".cf-step-counter");

  const currentStep = stepCounter
    ? Number(stepCounter.innerText.split(" ")[1])
    : 1;
  const numSteps = stepCounter
    ? Number(stepCounter.innerText.split(" ")[3]) + 1
    : 1;
  let percentage = 0;

  if (isDiagnosis && !location.pathname.includes("-flea-and-tick")) {
    percentage = (currentStep / numSteps) * 50;
  } else if (
    isMedicalHistory &&
    !location.pathname.includes("-flea-and-tick")
  ) {
    percentage = (currentStep / numSteps) * 50 + 50;
  } else {
    percentage = (currentStep / numSteps) * 100;
  }

  // console.debug({ percentage });

  if (quizNav) quizNav.style.width = `${percentage}%`;
}

export default component((node) => {
  const CFform = node.querySelector("[data-cf-form]");

  CF.ready(async function () {
    // console.debug("document.referrer", document.referrer);

    handleRangeSlider(CFform, node);
    handleProgressBar(0);

    // If Rx Quiz...
    if (isDiagnosis) {
      // Get param data
      const searchParams = new URLSearchParams(location.search);
      const petName = searchParams.get("pet");
      const petId = searchParams.get("petId");
      const issue = searchParams.get("issue");
      const pet_type = searchParams.get("type");
      const pet_age = searchParams.get("age");

      const rxQuizInfo = {
        id: CFform.getAttribute("data-cf-form"),
        name: window.location.pathname
          .split("/pages/")[1]
          .replaceAll("-", " ")
          .replace(/./, (x) => x.toUpperCase()),
      };

      localStorage.setItem("dp:rx-quiz-info", JSON.stringify(rxQuizInfo));

      // Reset form w/ new data
      await resetCustomerData({
        pets_name: petName,
        emr_pet_id: petId,
        quiz_type: issue,
      }).then(() => {
        // Hack to reload form after customer data reset
        if (
          node.querySelector('[name="pets_name"]').value !==
          CF.customer.data.pets_name
        ) {
          // But prevent runaway reloads.
          let reloadCount = localStorage.getItem("dp:quiz_reload") || 1;
          if (reloadCount && reloadCount >= 5) {
            alert("Something went wrong :(");
          } else {
            localStorage.setItem("dp:quiz_reload", reloadCount++);
            CFform.classList.add("submitting");
            location.reload();
          }
        } else {
          node.classList.remove("hidden");
        }
      });
    } else if (isMedicalHistory) {
      console.log(document.referrer);
      node.classList.remove("hidden");
    } else {
      node.classList.remove("hidden");
    }

    // FORM EVENTS
    // Prompt users if they attempt to leave the quiz
    window.onbeforeunload = function () {
      if (CFform.classList.contains("no-alert")) return;

      if (!CFform.classList.contains("submitting")) {
        // Save their data if logged-in
        if (CF.customer.isLoggedIn) {
          CF.customer.save();
        }

        return "This page is asking you to confirm that you want to leave — information you’ve entered may not be saved.";
      }
    };

    document.addEventListener("cf:field_changed", function () {
      handleRangeSlider(CFform, node);
    });

    document.addEventListener("cf:step_changed", function (event) {
      const fromIndex = event.detail.fromIndex;
      const toIndex = event.detail.toIndex;

      // Scroll to top
      window.scrollTo(0, 0);

      setTimeout(function () {
        handleProgressBar(toIndex);
      }, 50);

      // TRACKING: Track step if toIndex is greater than fromIndex
      if (toIndex > fromIndex && isAssessment) {
        trackCompletedStep(node);
      }

      // Get/set new current step title after sending GA event
      if (node.querySelector(".cf-step-title")) {
        node.setAttribute(
          "data-prev-step-title",
          document.querySelector(".cf-step-title").innerText
        );
      }
    });

    // Add a class before saving, used for beforeunload prompt
    // because submitting the form still triggers beforeunload.
    CFform.cfForm.beforeSave(function () {
      const eventOccurredAt = new Date();

      CF.customer
        .emit("changes_saved", CF.customer.unsavedChanges, eventOccurredAt)
        .then(function () {
          // Our servers have successfully received your custom event!
        })
        .catch(function (err) {
          // Uh-oh. Something's wrong.
          console.error("Could not emit event", err);
        });

      CFform.classList.add("submitting");
    });

    document.addEventListener("cf:form_submission_failed", function () {
      CFform.classList.remove("submitting");
    });

    document.addEventListener("cf:customer_save_failed", function (event) {
      const errors = event.detail.errors;
      const errorType = "CF form save failed - duplicate addresses?";
      const email = CF.customer.data["email"]
        ? CF.customer.data["email"]
        : "Anonymous (non logged-in user)";
      const data = CF.customer.data ? JSON.stringify(CF.customer.data) : null;
      const errorMessage = `URL: ${location.href}\n Referrer: ${
        document.referrer
      }\n UserAgent: ${
        navigator.userAgent
      }\n Customer Email: ${email}\nErrors: ${JSON.stringify(errors)}`;

      console.error(errors);
      // -> { email: ['is invalid'], ... }
      // Inform the user on how to fix the errors, etc.

      if (
        JSON.stringify(errors).includes(
          "Could not save form, please try again later"
        )
      ) {
        notifySlackQuiz(errorType, errorMessage);
      }
    });

    // Get/set initial step title for GA
    if (document.querySelector(".cf-step-title")) {
      node.setAttribute(
        "data-prev-step-title",
        document.querySelector(".cf-step-title").innerText
      );
    }
  });
});
