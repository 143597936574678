import Bugsnag from "@bugsnag/js";
import type { AlpineComponent } from "alpinejs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { applyShopifyDiscount } from "@/js/utils/applyShopifyDiscount";

dayjs.extend(utc);

type Props = {
  product: any;
  discount: string;
};

type State = {
  loading: boolean;
  date: string | null | undefined;
  handleSubmit: () => Promise<void>;
};

export function AnnualRenewal({
  product,
  discount,
}: Props): AlpineComponent<State> {
  const params = new URLSearchParams(window.location.search);
  const sellingPlan = product?.selling_plan_allocations?.find(
    ({ selling_plan_id }: any) => selling_plan_id
  );

  return {
    loading: false,
    get date() {
      const annualRenewalDate = params.get("annualRenewalDate");
      const nextChargeDate =
        // @ts-ignore
        window.Alpine?.store("subscription")?.subscription?.next_charge_date;

      return (
        annualRenewalDate ||
        (nextChargeDate ? dayjs.utc(nextChargeDate).format("MM-DD-YYYY") : null)
      );
    },
    // TODO: check if customer is a monthly member, if so then redirect to /account
    // init() {},
    async handleSubmit() {
      try {
        this.loading = true;
        const cartPayload = {
          items: [
            {
              id: product.id,
              quantity: 1,
              properties: {
                _isAnnualRenewal: true,
              },
              selling_plan: sellingPlan?.selling_plan_id
                ? sellingPlan.selling_plan_id
                : undefined,
            },
          ],
        };

        await useCart().clearCart();
        await useCart().addItemToCart(cartPayload);

        if (discount.length) {
          return applyShopifyDiscount(discount, "/checkout");
        }

        window.location.href = "/checkout";
      } catch (error: any) {
        Bugsnag.notify(error);
      } finally {
        this.loading = false;
      }
    },
  };
}
