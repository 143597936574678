/* eslint-disable no-undef */
/* eslint-disable no-console */

export function stageChanges(event) {
  // Set field changes to be saved via saveCustomerData()
  const columnKey = event.detail.columnKey;
  const value = event.detail.value;

  if (CF.customer.get(columnKey) !== value) {
    if (columnKey !== "password")
      console.debug("customer set:", columnKey, value);
    CF.customer.set(columnKey, value);
  }
}

export function saveCustomerData() {
  // Only save if there are unsaved changes
  if (Object.keys(CF.customer.unsavedChanges).length > 0) {
    if (CF.customer.isLoggedIn) {
      CF.customer.save();
      console.debug("customer data save:", CF.customer.data);
    } else {
      CF.customer.saveAsGuest();
      console.debug("saved as guest:", CF.customer.data);
    }
  }
}

export function initCustomerData(callback) {
  // ensure pet_type and quiz_type are set
  const params = new URLSearchParams(location.search);
  const petType = params.get("pet_type");
  const quizType = params.get("quiz_type");

  if (petType) {
    CF.customer.set("pet_type", petType);
  }

  if (quizType) {
    CF.customer.set("quiz_type", quizType);
  }

  if (CF.customer.isLoggedIn) {
    CF.customer.save().then(() => {
      console.debug("saved:", CF.customer.data);
      callback();
    });
  } else {
    CF.customer.saveAsGuest();
    console.debug("saved as guest:", CF.customer.data);
    callback();
  }
}

export async function resetCustomerData(newData) {
  const customerData = CF.customer.data;

  const keysToKeep = [
    "id",
    "shopify_id",
    "first_name",
    "last_name",
    "email",
    "phone",
    "password",
    "password_confirmation",
    "note",
    "tags",
    "accepts_marketing",
    "accepts_marketing_updated_at",
    "currency",
    "last_order_id",
    "last_order_name",
    "marketing_opt_in_level",
    "multipass_identifier",
    "orders_count",
    "total_spent",
    "average_order_amount",
    "state",
    "tax_exempt",
    "tax_exemptions",
    "verified_email",
    "admin_graphql_api_id",
    "addresses",
    "default_address",
    "has_note",
    "display_name",
    "locale",
    "image",
    "can_delete",
    "emr_customer_id",
    "emr_pet_id",
    "calendly",
    "rx_quiz_complete",
    "pet_type",
  ];

  // Does not work for nested columns (i.e. some_parent.some_child would only give you "some_parent")
  const currentKeys = Object.keys(customerData);

  currentKeys.forEach((key) => {
    if (keysToKeep.includes(key)) return;
    // Stage empty values
    CF.customer.set(key, "");
  });

  if (newData) {
    CF.customer.set(newData);
  }

  // Save updated customer data
  CF.customer.save().then(() => {
    return;
  });
}
