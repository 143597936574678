import { component } from "picoapp";

export default component((node) => {
  /* this is highly specific to the triage pages.
   * worth genericizing this some more
   */

  const anchor = node.href.split("#")[1];
  const target = document.querySelector(`#${anchor}`);
  const position = target.getBoundingClientRect().top;
  const offset = 70;

  node.addEventListener("click", (e) => {
    e.preventDefault();

    // scrollIntoView does not support any offset
    window.scrollTo({
      top: position - offset,
      behavior: "smooth",
    });
  });
});
